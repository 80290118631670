"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { memo } from "react";
import { useIntl } from "react-intl";
import { AeiLogoWithName } from "@/components/aei-logo/aei-logo";
import { CsisLogo } from "@/components/csis-logo/csis-logo";
import { SpearAiLogo } from "@/components/spear-ai-logo/spear-ai-logo";

export const PartnerSection = memo(() => {
  const intl = useIntl();

  return (
    <section className="py-12 md:py-24">
      <div className="flex flex-col items-center space-y-8">
        <div className="flex flex-col items-center space-y-4">
          <p className="mx-auto text-center text-base font-normal tracking-tight text-neutral-12 md:text-lg">
            {intl.formatMessage({
              defaultMessage: "Brought to you by",
              id: "O5LK8i",
            })}
          </p>
          <AeiLogoWithName className="w-36 md:w-48" />
        </div>

        <div className="flex flex-col items-center space-y-8 md:flex-row md:justify-center md:space-x-8 md:space-y-0 lg:space-x-16">
          <div className="flex flex-col items-center space-y-4">
            <p className="text-center text-base font-normal tracking-tight text-neutral-12 md:text-lg">
              {intl.formatMessage({
                defaultMessage: "Technology powered by",
                id: "JMWvum",
              })}
            </p>
            <SpearAiLogo className="w-36 md:w-48" />
          </div>
          <div className="flex flex-col items-center space-y-4">
            <p className="text-center text-base font-normal tracking-tight text-neutral-12 md:text-lg">
              {intl.formatMessage({
                defaultMessage: "In partnership with",
                id: "ArpmqW",
              })}
            </p>
            <CsisLogo className="w-36 md:w-48" />
          </div>
        </div>
      </div>

      <div className="mt-12 flex justify-center px-4">
        <p className="relative rounded-full border border-neutral-a-7 bg-white-a-9 px-4 py-1.5 text-sm leading-6 text-neutral-11 dark:bg-white-a-1">
          {intl.formatMessage(
            {
              defaultMessage: "{description} {readMore}",
              id: "zKuc1m",
            },
            {
              description: (
                <span className="hidden md:inline">
                  {intl.formatMessage({
                    defaultMessage: "Learn more about the history of DFS.",
                    id: "DOLKs2",
                  })}
                </span>
              ),
              readMore: (
                <Link
                  className="inline-flex items-center rounded-md font-semibold text-primary-12"
                  href="https://www.aei.org/defense-futures-simulator"
                  rel="noopener"
                  target="_blank"
                >
                  {intl.formatMessage({ defaultMessage: "Read more", id: "S++WdB" })}
                  <ArrowRightIcon
                    aria-hidden="true"
                    className="ml-1 size-4 -rotate-45 rtl:rotate-45 rtl:-scale-x-100"
                  />
                </Link>
              ),
            },
          )}
        </p>
      </div>
    </section>
  );
});

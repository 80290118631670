"use client";

import React from "react";
import { useIntl } from "react-intl";

export const HistorySection = () => {
  const intl = useIntl();

  return (
    <section className="relative isolate overflow-hidden bg-gradient-to-b from-neutral-1 to-neutral-2 py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <h1 className="text-2xl font-bold tracking-tight text-neutral-12 sm:text-3xl lg:text-4xl">
            {intl.formatMessage({
              defaultMessage: "Defense strategy is about choices",
              id: "i7aVtW",
            })}
          </h1>
          <p className="mt-4 text-lg leading-7 text-neutral-11 sm:text-xl sm:leading-8">
            {intl.formatMessage({
              defaultMessage:
                "Strategic choices should determine how funding is allocated among competing priorities in the defense budget. While a strategy set without regard for resource constraints risks being inexecutable in practice, a budget set without regard for strategy risks being ineffective and inefficient in how it allocates resources.",
              id: "G8PZ1a",
            })}
          </p>
        </div>

        <div className="mt-12 grid gap-y-12 md:grid-cols-2 md:gap-x-8 lg:mt-16 lg:grid-cols-12 lg:gap-x-12">
          <div className="md:col-span-1 lg:col-span-7">
            <h2 className="text-xl font-bold tracking-tight text-neutral-12 sm:text-2xl">
              {intl.formatMessage({
                defaultMessage: "Explore alternatives",
                id: "MTegFh",
              })}
            </h2>
            <div className="mt-4 text-base leading-7 text-neutral-11 sm:text-lg">
              <p>
                {intl.formatMessage({
                  defaultMessage:
                    "The simulator allows you to quickly explore a range of strategic choices and budget options—and understand the connections between them.",
                  id: "VGMcOE",
                })}
              </p>
              <p className="mt-4">
                {intl.formatMessage({
                  defaultMessage:
                    "DFS helps facilitate a better understanding of defense issues and tradeoffs, provides scholars and practitioners an easy and accessible way to develop and test alternative strategies and funding profiles, and advances the state of the art in defense analysis.",
                  id: "Li9jIV",
                })}
              </p>
            </div>
          </div>

          <div className="md:col-span-1 lg:col-span-5">
            <figure className="border-l border-neutral-10 pl-4 sm:pl-6">
              <blockquote className="text-lg font-semibold leading-8 tracking-tight text-neutral-12 sm:text-xl">
                <q>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "We do not have and probably never will have enough money to buy all the things we could effectively use for our defense.{lineBreak}{lineBreak}The choices we have to make would be difficult and painful even if our military budget were twice what it is today. The fact that we are dealing with a lesser sum only makes the choices harder and more painful.",
                      id: "mRK2gN",
                    },
                    {
                      lineBreak: <br />,
                    },
                  )}
                </q>
              </blockquote>
              <figcaption className="mt-6 flex items-center gap-x-4 object-fill">
                <div className="text-sm leading-6">
                  <div className="font-semibold text-neutral-11">
                    {intl.formatMessage({
                      defaultMessage: "Bernard Brodie",
                      id: "GnNtot",
                    })}
                  </div>
                  <div className="text-neutral-10">
                    {intl.formatMessage({
                      defaultMessage:
                        "Strategy in the Missile Age, published in 1959 when the U.S. defense budget was less than half its current size.",
                      id: "ehLc5U",
                    })}
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

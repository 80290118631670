"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Image from "next/image";
import Link from "next/link";
import { useIntl } from "react-intl";

export const HowDFSWorksSection = () => {
  const intl = useIntl();

  const steps = [
    {
      description: intl.formatMessage({
        defaultMessage:
          "Create a free account with just an email address. Save scenarios, collaborate, and test alternative approaches.",
        id: "toh0Of",
      }),
      image: "https://placehold.co/300x200@2x.png?text=placeholder",
      title: intl.formatMessage({ defaultMessage: "Make an Account", id: "3rSss3" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "Choose a baseline budget and strategy. All scenario changes are relative to this baseline.",
        id: "O/OCoA",
      }),
      image: "https://placehold.co/300x200@2x.png?text=placeholder",
      title: intl.formatMessage({ defaultMessage: "Select a Baseline", id: "ZvMqmH" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "The DFS Intelligent Assistant connects strategy and budgets, automating the process of finding choices that match your preferences.",
        id: "xBciyp",
      }),
      image: "https://placehold.co/300x200@2x.png?text=placeholder",
      title: intl.formatMessage({ defaultMessage: "Use the Intelligent Analysis", id: "fr+KzT" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "Our budget model estimates costs and force structure implications, including realistic constraints on alterations.",
        id: "PHdOny",
      }),
      image: "https://placehold.co/300x200@2x.png?text=placeholder",
      title: intl.formatMessage({ defaultMessage: "Model the Budget and Force Structure", id: "rHYhaW" }),
    },
    {
      description: intl.formatMessage({
        defaultMessage:
          "DFS continuously improves as more people use it, enhancing its understanding of strategy and budget interrelationships.",
        id: "FKVHZa",
      }),
      image: "https://placehold.co/300x200@2x.png?text=placeholder",
      title: intl.formatMessage({ defaultMessage: "Learn and Evolve", id: "au5hSe" }),
    },
  ];

  return (
    <section className="isolate bg-gradient-to-b from-neutral-1 to-neutral-3 py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <header className="mx-auto mb-16 max-w-2xl text-center">
          <h1 className="mb-4 text-3xl font-bold tracking-tight text-neutral-12 sm:text-4xl">
            {intl.formatMessage({
              defaultMessage: "How DFS Works",
              id: "RLVx1F",
            })}
          </h1>
          <p className="text-xl text-neutral-9">
            {intl.formatMessage({
              defaultMessage: "Discover the simple steps to leverage our Defense Force Structure tool",
              id: "aw1+bQ",
            })}
          </p>
        </header>

        <div className="space-y-24">
          {steps.map((step, index) => (
            <div
              className={`flex flex-col ${index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"} items-center gap-12`}
            >
              <div className="w-full lg:w-1/2">
                <Image
                  alt={step.title}
                  className="rounded-lg shadow-xl"
                  height={400}
                  src={step.image}
                  width={600}
                />
              </div>
              <div className="w-full space-y-4 lg:w-1/2">
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <h2 className="text-2xl font-semibold text-neutral-12">{`${index + 1}. ${step.title}`}</h2>
                <p className="text-lg text-neutral-10">{step.description}</p>
                {index === 0 && (
                  <Link
                    className="inline-flex items-center rounded-md bg-primary-dark-9 px-3.5 py-2.5 text-sm font-semibold text-primary-contrast shadow-sm hover:bg-primary-dark-10"
                    href="/sign-up"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Sign up",
                      id: "8HJxXG",
                    })}
                    <ArrowRightIcon className="ml-2 size-4" />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

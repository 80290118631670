import(/* webpackMode: "eager", webpackExports: ["CallToActionSection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/call-to-action-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HistorySection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/history-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HowDFSWorksSection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/how-dfs-works-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PartnerSection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/partner-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialSection"] */ "/home/runner/work/dfs/dfs/packages/dfs-app/src/app/(product)/landing/testimonial-section.tsx");
